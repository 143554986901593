import React, { Fragment } from "react";
import { useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { Styled, css } from "theme-ui";

function Posts() {
  const data = useStaticQuery(query);
  const {
    allBlogPost: { edges: posts },
  } = data;

  return (
    <main>
      {posts.map(({ node }) => {
        const title = node.title || node.slug;
        return (
          <Fragment key={node.slug}>
            <div>
              <Styled.h2
                css={css({
                  mb: 1,
                })}
              >
                <Styled.a
                  as={Link}
                  css={{
                    textDecoration: `none`,
                  }}
                  to={node.slug}
                >
                  {title}
                </Styled.a>
              </Styled.h2>
              <small>{node.date}</small>
              <Styled.p>{node.excerpt}</Styled.p>
            </div>
          </Fragment>
        );
      })}
    </main>
  );
}

export default Posts;

export const query = graphql`
  query AppPostsQuery {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    allBlogPost(sort: { fields: [date, title], order: DESC }, limit: 1000) {
      edges {
        node {
          id
          excerpt
          slug
          title
          date(formatString: "MMMM DD, YYYY")
          tags
        }
      }
    }
  }
`;
