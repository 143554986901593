import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Posts from "../components/posts";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="One-Sided Conversations -- AKA My Blog" />
      <Posts />
    </Layout>
  );
};

export default IndexPage;
